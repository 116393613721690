import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath } from 'react-router';
import { observer } from 'mobx-react';
import Button, { PRIMARY, SIZE_SMALL } from '@/common/components/Button/Button';
import Link from '@/common/components/Link/Link';
import { MULTI_PROJECT_HISTORY_PAGE } from '@/common/constants/pages';
import styles from './DriveProjectSummaryCell.module.less';
function DriveProjectSummaryCell(props) {
    const { onSuggestionClick, summary } = props;
    if (!summary?.suggest && summary?.project?.title && summary?.stage?.title) {
        return _jsx(Link, { to: generatePath(MULTI_PROJECT_HISTORY_PAGE, { id: summary.project.id }), children: summary.stage.title });
    }
    return (_jsx(Button, { size: SIZE_SMALL, className: styles.summaryCellButton, color: PRIMARY, onClick: onSuggestionClick, children: "Add" }));
}
export default observer(DriveProjectSummaryCell);
