var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from 'mobx';
import memoize from 'lodash/memoize';
import { MAIN_SHAPES_IDS, SHAPE_OTHER } from '@/product/entity/Cutshape';
import DictionaryConverter from '../services/DictionaryConverter';
export default class DictionaryRepository {
    dictionaries;
    static modifyFancyColors(colorHue) {
        const result = [];
        colorHue.forEach((item) => {
            result.push(item);
            if (item.children && item.children.length) {
                item.children.forEach((child) => {
                    result.push(child);
                });
            }
        });
        return result;
    }
    // https://octonus-teams.com/jira/browse/CUT-7019
    static modifyShapes(shapes) {
        const shapeOther = shapes.find((s) => s.id === SHAPE_OTHER);
        if (shapeOther) {
            // add all rare cutshapes to 'Other' as children
            shapeOther.children = shapes.filter((s) => MAIN_SHAPES_IDS.includes(s.id) === false);
        }
        return shapes.filter((s) => MAIN_SHAPES_IDS.includes(s.id) === true);
    }
    initDictionaries(dictionariesData) {
        dictionariesData.colorHueAll = DictionaryRepository.modifyFancyColors(dictionariesData.colorHue);
        dictionariesData.cutShape = DictionaryRepository.modifyShapes(dictionariesData.cutShape);
        this.dictionaries = DictionaryConverter.convert(dictionariesData);
    }
    constructor() {
        this.getColorById = memoize(this.getColorById);
        this.getClarityById = memoize(this.getClarityById);
        this.getCutQualityById = memoize(this.getCutQualityById);
        this.getSymmetryById = memoize(this.getSymmetryById);
        this.getPolishById = memoize(this.getPolishById);
        this.getFluorescenceStrengthById = memoize(this.getFluorescenceStrengthById);
        this.getFancyGradeById = memoize(this.getFancyGradeById);
        this.getFancyColorDescHueById = memoize(this.getFancyColorDescHueById);
        this.getColorModifierById = memoize(this.getColorModifierById);
        this.getGirdleThicknessGradeById = memoize(this.getGirdleThicknessGradeById);
        this.getFluorescenceColorById = memoize(this.getFluorescenceColorById);
        this.getLaboratoryById = memoize(this.getLaboratoryById);
        this.getCuletSizeGradeById = memoize(this.getCuletSizeGradeById);
        this.getColorByTitle = memoize(this.getColorByTitle);
        this.getCutShapeById = memoize(this.getCutShapeById);
    }
    getFancyGradePercentGrade(id) {
        if (!this.dictionaries) {
            throw new Error('Dictionary not found');
        }
        return ((this.dictionaries.fancyGrade.length - this.dictionaries.fancyGrade.findIndex((i) => i.id === id)) *
            (100 / this.dictionaries.fancyGrade.length));
    }
    getColorById(id) {
        return this.findItemInDictionaryById('color', id);
    }
    getClarityById(id) {
        return this.findItemInDictionaryById('clarity', id);
    }
    getCutQualityById(id) {
        return this.findItemInDictionaryById('cutQuality', id);
    }
    getSymmetryById(id) {
        return this.findItemInDictionaryById('symmetry', id);
    }
    getPolishById(id) {
        return this.findItemInDictionaryById('polish', id);
    }
    getFluorescenceStrengthById(id) {
        return this.findItemInDictionaryById('fluorIntensity', id);
    }
    getFancyGradeById(id) {
        return this.findItemInDictionaryById('fancyGrade', id);
    }
    getFancyColorDescHueById(id) {
        return this.findItemInDictionaryById('colorHueAll', id);
    }
    getColorModifierById(id) {
        return this.findItemInDictionaryById('colorModifier', id);
    }
    getGirdleThicknessGradeById(id) {
        return this.findItemInDictionaryById('girdleThicknessGrade', id);
    }
    getFluorescenceColorById(id) {
        return this.findItemInDictionaryById('fluorColor', id);
    }
    getLaboratoryById(id) {
        return this.findItemInDictionaryById('laboratory', id);
    }
    getCuletSizeGradeById(id) {
        return this.findItemInDictionaryById('culet', id);
    }
    getCutShapeById(id) {
        return this.findItemInDictionaryById('cutShape', id);
    }
    getColorByTitle(title) {
        return this.findItemInDictionaryByTitle('color', title);
    }
    getIndexByColorTitle(title) {
        return this.findIndexInDictionaryByTitle('color', title);
    }
    getMilkyById(id) {
        return this.findItemInDictionaryById('milky', id);
    }
    getTreatmentById(id) {
        return this.findItemInDictionaryById('treatment', id);
    }
    getServiceById(id) {
        return this.findItemInDictionaryById('services', id);
    }
    getTaskStateById(id) {
        return this.findItemInDictionaryById('taskStates', id);
    }
    getTaskTypeById(id) {
        return this.findItemInDictionaryById('taskTypes', id);
    }
    getRenderModeById(id) {
        return this.findItemInDictionaryById('renderingMode', id);
    }
    findItemInDictionaryById(name, id) {
        return this.dictionaries?.[name]?.find((i) => i.id === id) ?? null;
    }
    findItemInDictionaryByTitle(name, title) {
        return (this.dictionaries?.[name]?.find((i) => i.title?.toUpperCase() === title.toUpperCase()) ?? null);
    }
    findIndexInDictionaryByTitle(name, title) {
        return (this.dictionaries?.[name]?.findIndex((i) => i.title?.toUpperCase() === title.toUpperCase()) ??
            null);
    }
}
__decorate([
    observable.ref
], DictionaryRepository.prototype, "dictionaries", void 0);
export const dictionaryRepository = new DictionaryRepository();
