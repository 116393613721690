import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { observer } from 'mobx-react';
import cls from 'classnames';
import styles from './RichText.module.less';
function RichText(props, ref) {
    const { value, className } = props;
    return _jsx("div", { ref: ref, className: cls(styles.richText, className), dangerouslySetInnerHTML: { __html: value } });
}
export default observer(forwardRef(RichText));
