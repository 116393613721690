import { isNumeric, isString } from '@cutwise/utils';
export default class SpreadFormatter {
    static format(value, fractionNumber, postfix) {
        if (!isNumeric(value) || !isNumeric(fractionNumber) || !isString(postfix)) {
            throw new Error('Invalid arguments');
        }
        if (value === 0) {
            return `0${postfix}`;
        }
        return `${value > 0 ? '+' : ''}${value.toFixed(fractionNumber)}${postfix}`;
    }
}
