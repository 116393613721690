import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';
export const COLOR_FILTER_SIZE = 9;
export const CLARITY_FILTER_SIZE = 9;
export const CUT_QUALITY_FROM = 1;
export const SYMMETRY_FROM = 1;
export const POLISH_FROM = 1;
export const mergeExtraDictionaryItems = (dictionary, from, to) => {
    const sortedItems = sortBy(dictionary, (item) => item.position);
    const sortedItemsWithValues = sortedItems.map((item) => ({ ...item, value: item.id }));
    if (from || (to && sortedItemsWithValues.length > to)) {
        if (from) {
            const firstElementIndex = from;
            // todo
            sortedItemsWithValues[firstElementIndex].value = slice(sortedItemsWithValues, 0, firstElementIndex + 1).map((item) => item.value);
        }
        if (to) {
            const lastElementIndex = to - 1;
            // todo
            sortedItemsWithValues[lastElementIndex].value = slice(sortedItemsWithValues, lastElementIndex).map((item) => item.value);
            sortedItemsWithValues[lastElementIndex].title += '+';
            sortedItemsWithValues[lastElementIndex].short += '+';
        }
        return slice(sortedItemsWithValues, from, to);
    }
    return sortedItemsWithValues;
};
export default class DictionaryConverter {
    static convert(dictionariesData) {
        const colorSanitized = mergeExtraDictionaryItems(dictionariesData.color, 0, COLOR_FILTER_SIZE);
        const claritySanitized = mergeExtraDictionaryItems(dictionariesData.clarity, 0, CLARITY_FILTER_SIZE);
        const cutQualitySanitized = mergeExtraDictionaryItems(dictionariesData.cutQuality, CUT_QUALITY_FROM);
        const symmetrySanitized = mergeExtraDictionaryItems(dictionariesData.symmetry, SYMMETRY_FROM);
        const girdleThicknessGradeSanitized = mergeExtraDictionaryItems(dictionariesData.girdleThicknessGrade);
        const culetSizeGradeSanitized = mergeExtraDictionaryItems(dictionariesData.culet);
        const polishSanitized = mergeExtraDictionaryItems(dictionariesData.polish, POLISH_FROM);
        const fancyGradeSanitized = mergeExtraDictionaryItems(dictionariesData.fancyGrade, 0, 7);
        const fluorIntensitySanitized = mergeExtraDictionaryItems(dictionariesData.fluorIntensity);
        const setupPresetsSelectData = dictionariesData.setupPresets.map((sp) => ({ id: sp.id, title: sp.title }));
        return {
            ...dictionariesData,
            colorSanitized,
            claritySanitized,
            cutQualitySanitized,
            symmetrySanitized,
            girdleThicknessGradeSanitized,
            culetSizeGradeSanitized,
            polishSanitized,
            fancyGradeSanitized,
            fluorIntensitySanitized,
            setupPresetsSelectData,
        };
    }
}
