import { jsx as _jsx } from "react/jsx-runtime";
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { CONTEXT_B2B } from '@/common/constants/context';
import DriveProjectSummaryCell from '@/drive/multiProjects/components/DriveProjectSummaryCell/DriveProjectSummaryCell';
export const FIELD_DRIVE_PROJECT_SUMMARY = {
    label: 'Multistage',
    fullLabel: 'Multistage',
    key: 'driveProject',
    type: FieldType.Custom,
    getter(p, _, { onDriveSuggestionClick, }) {
        return _jsx(DriveProjectSummaryCell, { summary: p.driveProject, onSuggestionClick: () => onDriveSuggestionClick(p) });
    },
    getterPlain(p) {
        return p.driveProject ?? null;
    },
    iteratee: null,
    decorator: emptyDecorator,
    contexts: [CONTEXT_B2B],
};
