import {
  TYPE_DIAMOND,
  TYPE_DIAMOND_COLORLESS,
  TYPE_DIAMOND_FANCY,
  TYPE_LGD,
  TYPE_LGD_COLORLESS,
  TYPE_LGD_FANCY,
} from '../constants/productTypes';
import SingleResponse from './SingleResponse';
import CollectionResponse from './CollectionResponse';
import { SECURITY_CHECK_ACL } from '../../security/constants/securityCheck';
import { HEADER_X_EXPENSES, HEADER_X_NEXT_PAYMENT, HEADER_X_TOTAL_COUNT } from '../../common/network/CutwiseAPIClient';

// Prevent multiple simultaneous calls for same data
export default class ProductRequestHelper {
  promiseCache = {};

  /**
   * @param {ProductAPI} productAPI
   */
  constructor(productAPI) {
    this.productAPI = productAPI;
  }

  /**
   * @param {number} id
   * @param {string} productType
   * @param {boolean} shouldDumpResponse
   * @return {Promise}
   */
  getOne(id, productType = TYPE_DIAMOND_COLORLESS, shouldDumpResponse = false) {
    this.productAPI.updatePath(productType);

    return this.productAPI.fetchItem(id, shouldDumpResponse).then((res) => {
      const product = res.data;
      this.promiseCache[id] = null;

      return new SingleResponse(product);
    });
  }

  /**
   * @param {number[]} ids
   * @param {?Sort} sort
   * @param {?boolean} indexOnly
   * @param {?string} scope
   * @param {?string} productType
   * @return {Promise<CollectionResponse>}
   */
  getByIds(ids, sort = null, indexOnly = null, scope = null, productType = null) {
    this.productAPI.updatePath(productType);

    let isLabGrown = null;
    if (productType === TYPE_LGD || productType === TYPE_LGD_COLORLESS || productType === TYPE_LGD_FANCY) {
      isLabGrown = true;
    }
    if (productType === TYPE_DIAMOND || productType === TYPE_DIAMOND_COLORLESS || productType === TYPE_DIAMOND_FANCY) {
      isLabGrown = false;
    }

    if (indexOnly === true) {
      return this.productAPI.fetchProductsById(ids, isLabGrown, sort, indexOnly, scope);
    }

    if (!ids || ids.length === 0) {
      return Promise.resolve(new CollectionResponse([], ids.length));
    }

    return this.productAPI.fetchProductsById(ids, isLabGrown, sort, indexOnly, scope).then((products) => {
      const fetchedProducts = products && Array.isArray(products) ? products : [];
      return new CollectionResponse(fetchedProducts, ids.length);
    });
  }

  /**
   * @param {?Filters} filters
   * @param {?Sort} sort
   * @param {?number} offset
   * @param {?number} limit
   * @param {?boolean} indexOnly
   * @param {?string} productType
   * @param {?number} b2bId
   * @param {?string} securityCheck
   * @return {Promise}
   */
  getByFilters(
    filters = null,
    sort = null,
    offset = null,
    limit = null,
    indexOnly = false,
    productType = TYPE_DIAMOND_COLORLESS,
    b2bId = null,
    securityCheck = SECURITY_CHECK_ACL,
  ) {
    this.productAPI.updatePath(productType);
    this.productAPI.b2bContext = b2bId;

    if (indexOnly === true) {
      return this.productAPI.fetchProductsByFilters(filters, sort, offset, limit, indexOnly, securityCheck);
    }

    // todo https://developer.mozilla.org/en-US/docs/Web/API/AbortController
    return this.productAPI.fetchProductsByFilters(filters, sort, offset, limit, false, securityCheck).then((res) => {
      // eslint-disable-next-line new-cap
      const products = res.content ? res.content.map((d) => new this.productAPI.entityConstructor(d)) : [];

      return new CollectionResponse(
        products,
        res.networkResponse.headers.get(HEADER_X_TOTAL_COUNT),
        res.networkResponse.headers.get(HEADER_X_EXPENSES),
        res.networkResponse.headers.get(HEADER_X_NEXT_PAYMENT),
      );
    });
  }
}
