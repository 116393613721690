import { HOME_PAGE, LOGIN_REDIRECT_PAGE } from '@/common/constants/pages';
import { addPrefixToHost } from '@/common/helpers/addPrefixToHost';
import Notifier from '@/common/services/Notifier';
import Storage, { KEY_ACCESS_TOKEN, KEY_ACCESS_TOKEN_LEGACY, KEY_AUTH_TOKEN, KEY_CONSTANTS, KEY_TOKEN, KEY_URI_AFTER_AUTH, KEY_USER, KEY_WAS_LOGGED_OUT, } from '@/common/services/Storage';
import config from '@/config';
import { NETBOX_ORIGIN } from '@/netbox/constants/network';
export default class Auth {
    static clearAuthData() {
        Storage.removeObject(KEY_AUTH_TOKEN, Storage.STORAGE_LOCAL);
        Storage.removeObject(KEY_USER, Storage.STORAGE_LOCAL);
        Storage.removeObject(KEY_CONSTANTS, Storage.STORAGE_LOCAL);
        // remove deprecated keys values, in cases when we update app version, but some users still have
        Storage.removeObject(KEY_TOKEN, Storage.STORAGE_LOCAL);
        Storage.removeObject(KEY_ACCESS_TOKEN, Storage.STORAGE_LOCAL);
        Storage.removeObject(KEY_ACCESS_TOKEN_LEGACY, Storage.STORAGE_LOCAL);
    }
    static redirectToLoginPage() {
        // do not save "LOGIN_REDIRECT_PAGE" path in store, because it might lead to circle
        if (window.location.pathname !== LOGIN_REDIRECT_PAGE) {
            Storage.saveObject(KEY_URI_AFTER_AUTH, window.location.href, Storage.STORAGE_SESSION);
            Storage.removeObject(KEY_AUTH_TOKEN, Storage.STORAGE_LOCAL);
        }
        window.location.href = Auth.generateAuthUrl();
    }
    // logout and redirects to main listing page
    static logout() {
        Auth.clearAuthData();
        window.location.href = `${window.location.origin}${HOME_PAGE}`;
    }
    // removes invalid token and redirects to backend login page
    static logoutAfterSessionExpiration() {
        Storage.saveObject(KEY_WAS_LOGGED_OUT, true, Storage.STORAGE_SESSION);
        Auth.clearAuthData();
        Auth.redirectToLoginPage();
    }
    // generates auth server url
    static generateAuthUrl() {
        const origin = window.location.origin;
        const clientId = origin.includes(NETBOX_ORIGIN) ? config.NETBOX_CLIENT_ID : config.CLIENT_ID;
        const host = origin.includes(NETBOX_ORIGIN) ? window.location.origin : addPrefixToHost('auth');
        return `${host}/api/oauth/v2/auth?client_id=${clientId}&grant_type=token&redirect_uri=${origin}/auth&response_type=token`;
    }
    // generates logout server url
    static generateLogoutUrl() {
        const host = origin.includes(NETBOX_ORIGIN) ? window.location.origin : addPrefixToHost('auth');
        return `${host}/logout`;
    }
    // this function should be called after refreshing of page
    static showNoticeAboutSessionExpirationIfNeeded() {
        const wasLoggedOut = Storage.getObject(KEY_WAS_LOGGED_OUT, Storage.STORAGE_SESSION);
        if (wasLoggedOut) {
            Storage.removeObject(KEY_WAS_LOGGED_OUT, Storage.STORAGE_SESSION);
            Notifier.warn('You were logged out due to session expiration.', { delay: 10, toastId: null });
        }
    }
}
