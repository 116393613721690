import { jsx as _jsx } from "react/jsx-runtime";
import { emptyDecorator } from '@/catalog/constants/emptyDecorator';
import { FieldType } from '@/catalog/constants/fieldTypes';
import { ALL_CONTEXTS } from '@/common/constants/context';
import { FIRE } from '@/media/entity/SetupPreset';
import MetricMap from '@/metric/components/MetricMap/MetricMap';
import Diamond from '@/product/entity/Diamond';
export const FIELD_FIRE_MAP = {
    label: 'Fire Map',
    fullLabel: 'Fire Map',
    key: 'fireMap',
    type: FieldType.Media,
    spGroupId: null,
    icon: '',
    getter(p, viewSettings, maxScale) {
        if (p instanceof Diamond && p.fireMetric) {
            return (_jsx(MetricMap, { isSingleScaleEnabled: viewSettings.resultedIsSingleScale, maxScale: maxScale, mediaSize: viewSettings.mediaSize, metric: p.fireMetric, product: p, setupPresetId: FIRE }));
        }
        return null;
    },
    getterPlain(p) {
        return p instanceof Diamond ? (p.fireMetric?.map ?? null) : null;
    },
    iteratee: null,
    contexts: ALL_CONTEXTS,
    decorator: emptyDecorator,
};
