import { HttpStatus } from '@/common/network/constants/httpStatus';
export default class QueryResult {
    content;
    networkResponse;
    cacheHit = false;
    // temporal getter for supporting old interface
    // @deprecated
    get data() {
        return this.content;
    }
    // temporal getter for supporting old interface
    // @deprecated
    get ok() {
        return this.cacheHit || this.networkResponse?.ok;
    }
    get status() {
        return this.cacheHit ? HttpStatus.OK : this.networkResponse?.status;
    }
}
