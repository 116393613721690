import {
  MEDIA_SIZE_160, MEDIA_SIZE_1920,
  MEDIA_SIZE_240,
  MEDIA_SIZE_320,
  MEDIA_SIZE_480,
  MEDIA_SIZE_640,
  MEDIA_SIZE_960,
} from '../constants/sizes';

export const VIDEO_SUB_FORMAT_FSET_160 = 72;
export const VIDEO_SUB_FORMAT_FSET_240 = 88;
export const VIDEO_SUB_FORMAT_FSET_320 = 73;
export const VIDEO_SUB_FORMAT_FSET_480 = 74;
export const VIDEO_SUB_FORMAT_FSET_640 = 76;
export const VIDEO_SUB_FORMAT_FSET_960 = 75;
export const VIDEO_SUB_FORMAT_FSET_1200 = 86;
export const VIDEO_SUB_FORMAT_FSET_1920 = 77;

export const VIDEO_SUB_FORMAT_FSET_BY_SIZE = {
  [MEDIA_SIZE_160]: VIDEO_SUB_FORMAT_FSET_160,
  [MEDIA_SIZE_240]: VIDEO_SUB_FORMAT_FSET_240,
  [MEDIA_SIZE_320]: VIDEO_SUB_FORMAT_FSET_320,
  [MEDIA_SIZE_480]: VIDEO_SUB_FORMAT_FSET_480,
  [MEDIA_SIZE_640]: VIDEO_SUB_FORMAT_FSET_640,
  [MEDIA_SIZE_960]: VIDEO_SUB_FORMAT_FSET_960,
  [MEDIA_SIZE_1920]: VIDEO_SUB_FORMAT_FSET_1920,
};

export const IMAGE_SUB_FORMAT_FULL = 64;
export const IMAGE_SUB_FORMAT_160 = 79;
export const IMAGE_SUB_FORMAT_240 = 89;
export const IMAGE_SUB_FORMAT_320 = 80;
export const IMAGE_SUB_FORMAT_480 = 81;
export const IMAGE_SUB_FORMAT_640 = 82;
export const IMAGE_SUB_FORMAT_960 = 83;
export const IMAGE_SUB_FORMAT_1920 = 85;
export const IMAGE_SUB_FORMAT_1200 = 87;

export const IMAGE_SUB_FORMAT_BY_SIZE = {
  [MEDIA_SIZE_160]: IMAGE_SUB_FORMAT_160,
  [MEDIA_SIZE_240]: IMAGE_SUB_FORMAT_240,
  [MEDIA_SIZE_320]: IMAGE_SUB_FORMAT_320,
  [MEDIA_SIZE_480]: IMAGE_SUB_FORMAT_480,
  [MEDIA_SIZE_640]: IMAGE_SUB_FORMAT_640,
  [MEDIA_SIZE_960]: IMAGE_SUB_FORMAT_960,
  [MEDIA_SIZE_1920]: IMAGE_SUB_FORMAT_1920,
};

export const SIMPLE_REPORT_COLOR = 37;
export const POLISH_ANGLES_REPORT_BOTH_ANGLES = 40;
export const PLOT_ACTUAL_BOTH_AXIS_ASYM = 43;
export const PLOT_BOTH_AXES_SYM_COR = 46;
export const DIAMETER_REPORT_COLOR = 49;

export const AVAILABLE_POLISHES = [POLISH_ANGLES_REPORT_BOTH_ANGLES, PLOT_ACTUAL_BOTH_AXIS_ASYM, SIMPLE_REPORT_COLOR, DIAMETER_REPORT_COLOR, PLOT_BOTH_AXES_SYM_COR];
export const POLISHES_SORTING = [SIMPLE_REPORT_COLOR, POLISH_ANGLES_REPORT_BOTH_ANGLES, PLOT_ACTUAL_BOTH_AXIS_ASYM, PLOT_BOTH_AXES_SYM_COR, DIAMETER_REPORT_COLOR];

export default class MediaSubFormat {
  /**
   * @param {number} id
   * @param {number} r
   */
  constructor({
    id, r,
  }) {
    this.id = id;
    this.r = r;
  }

  isFsetSubFormat() {
    return [
      VIDEO_SUB_FORMAT_FSET_160,
      VIDEO_SUB_FORMAT_FSET_240,
      VIDEO_SUB_FORMAT_FSET_320,
      VIDEO_SUB_FORMAT_FSET_480,
      VIDEO_SUB_FORMAT_FSET_640,
      VIDEO_SUB_FORMAT_FSET_960,
      VIDEO_SUB_FORMAT_FSET_1920,
      VIDEO_SUB_FORMAT_FSET_1200,
    ].includes(this.id);
  }

  isImageSubFormat() {
    return [
      IMAGE_SUB_FORMAT_FULL,
      IMAGE_SUB_FORMAT_160,
      IMAGE_SUB_FORMAT_320,
      IMAGE_SUB_FORMAT_480,
      IMAGE_SUB_FORMAT_640,
      IMAGE_SUB_FORMAT_960,
      IMAGE_SUB_FORMAT_1920,
      IMAGE_SUB_FORMAT_1200,
      IMAGE_SUB_FORMAT_240,
    ].includes(this.id);
  }
}
